body {
  background: #D4D4D4;
  background-image: url("../public/background.jpg");
  height: 700px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-attachment: fixed;
}

.login-logo {
  height: 150px; /* set a default height*/
  margin-top: -75px; /* move image up fo 75px */
}

.mt-10 {
  margin-top: 10rem;
}

.success-log {
  color: springGreen;
}

.info-log {
  color: #5e91ff;
}

.error-log {
  color: red;
}

.delta-sym {
  color: #0fd464;
  font-size: 23px;
}

.rounded {
  border-radius: 10px;
}

.shadow {
  box-shadow: 0 0 20px lightGray;
}

.overflow-auto {
  overflow: auto;
}

.border-start {
  border-left: 1px solid lightGray;
}

.border-end {
  border-right: 1px solid lightGray;
}

/* .modal-card {
  width: 60%;
  min-width: 470px;
} */

/* .chart {
  background-color: white;
} */

@media (min-width: 769px) {
  .wide-modal {
    width: 80%;
  }
}
